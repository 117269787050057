import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminShopList } from '../Admin/SuperAdmin/AdminShopList';
import { AdminEditSubscriptionPackages } from '../Admin/SuperAdmin/AdminEditSubscriptionPackages';
import { AdminShopDetails } from '../Admin/SuperAdmin/AdminShopDetails';
import { ShopOwnerShopDetails } from '../Admin/ShopOwner/ShopOwnerShopDetails';
import { AdminCreativesHubUserAccountDetails } from '../Admin/CreativesHub/AdminCreativesHubUserAccountDetails';
import { AdminBlog } from '../Admin/SuperAdmin/AdminBlog';
import { AdminDashboard } from '../Admin/SuperAdmin/AdminDashboard';
import { AdminShopReviews } from '../Admin/SuperAdmin/AdminShopReviews';
import { AdminManageShopReviews } from '../Admin/SuperAdmin/AdminManageShopReviews';
import { AdminManageShopApplications } from '../Admin/SuperAdmin/AdminManageShopApplications';
import { AdminCreativesHubUsers } from '../Admin/SuperAdmin/AdminCreativesHubUsers';
import { AdminOurUsers } from '../Admin/SuperAdmin/AdminOurUsers';
import { AccountManagement } from '../Admin/UserAccount/AccountManagement';

export const ProtectedRoutes = () => {

  return (
    <Routes>
      <Route path="dashboard" element={<AdminDashboard />} />
      <Route path="shops" element={<AdminShopList />} />
      <Route path="creatives-hub-users" element={<AdminCreativesHubUsers />} />
      <Route path="our-users" element={<AdminOurUsers />} />
      <Route path="user-account" element={<AccountManagement />} />
      <Route path="shops/:shop_id" element={<AdminShopDetails />} />
      <Route path="manage-shop/:shop_id" element={<ShopOwnerShopDetails />} />
      <Route path="creatives-hub-users/:hub_user_id" element={<AdminCreativesHubUserAccountDetails />} />
      <Route path="blogs" element={<AdminBlog />} />
      <Route path="packages" element={<AdminEditSubscriptionPackages />} />
      <Route path="reviews" element={<AdminShopReviews />} />
      <Route path="reviews/:shop_id" element={<AdminManageShopReviews />} />
      <Route path="applications" element={<AdminManageShopApplications />} />
    </Routes>
  );
};
