import React from "react";
import { toast } from "react-toastify";
import UserService from "../../../services/UserService";
import "./styles/user-details.css";

const userService = new UserService();

export const UserDetails = ({ userData, setUserData, originalData, setOriginalData, editable, setEditable, setLoading }) => {
  const handleUserChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleUpdateUser = async () => {
    if (!editable) return;
    setLoading(true);

    try {
      const updatedFields = {};
      Object.keys(userData).forEach((key) => {
        if (userData[key] !== originalData[key]) {
          updatedFields[key] = userData[key];
        }
      });

      if (Object.keys(updatedFields).length === 0) {
        toast.info("No changes detected.");
        setEditable(false);
        return;
      }

      await userService.updateUser(updatedFields);
      toast.success("Profile updated successfully!");
      setEditable(false);
      setOriginalData(userData);
    } catch (error) {
      toast.error(error.message || "Failed to update profile.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="account-form">
      <h2>Your Profile</h2>

      <label>First Name</label>
      <input type="text" name="first_name" value={userData.first_name} onChange={handleUserChange} disabled={!editable} />

      <label>Last Name</label>
      <input type="text" name="last_name" value={userData.last_name} onChange={handleUserChange} disabled={!editable} />

      <label>Phone Number</label>
      <input type="text" name="username" value={userData.username} onChange={handleUserChange} disabled={!editable} />

      <label>Email</label>
      <input type="email" value={userData.email} disabled />

      {!editable ? <button onClick={() => setEditable(true)}>Edit Profile</button> : <button onClick={handleUpdateUser}>Save Profile</button>}
    </div>
  );
};
