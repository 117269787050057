import ApiService from "./ApiService";

class ReviewService extends ApiService {

  // Public Endpoints
  getReviews = async (shopId) => {
    return await this.get(`/review/shop/${shopId}`, { requiresAuth: false });
  };

  addReview = async (shopId, reviewData) => {
    return await this.post(`/review/shop/${shopId}`, reviewData, { requiresAuth:  true });
  };

  // DELETE METHOD
  deleteReview = async (reviewUid) => {
    return await this.delete(`/review/${reviewUid}/`, { requiresAuth:  true });
  };
}

export default ReviewService;
