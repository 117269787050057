import ApiService from "./ApiService";

class CartService extends ApiService {
  // List all carts
  listCarts = async (params = {}) => {
    const queryParams = this.createQueryParams(params);
    const endpoint = `/cart${queryParams ? `?${queryParams}` : ""}`;
    return await this.get(endpoint, { requiresAuth: true });
  };

  // View a specific cart by UID
  viewCart = async (cartUid) => {
    if (!cartUid) {
      console.error("Cart UID is required to view a cart.");
      throw new Error("Cart UID is required to view a cart.");
    }
    const endpoint = `/cart/${cartUid}`;
    return await this.get(endpoint, { requiresAuth: true });
  };

  // Create a new cart for a specific shop
  createCart = async (shopUid) => {
    const endpoint = `/cart/`;
    const data = shopUid ? { shop: shopUid } : {};
    return await this.post(endpoint, data, { requiresAuth: true });
  };

  // Add a product to a specific cart
  addItemToCart = async (cartId, productUid, qty = 1) => {
    if (!cartId || !productUid || qty <= 0) {
      console.error("Cart ID, Product UID, and Quantity are required.");
      throw new Error("Cart ID, Product UID, and Quantity are required.");
    }
    const endpoint = `/cart/${cartId}/item/`;
    const data = { product: productUid, qty };
    return await this.post(endpoint, data, { requiresAuth: true });
  };

  // Remove a product from a cart
  removeItemFromCart = async (cartId, productUid) => {
    if (!cartId || !productUid) {
      console.error(
        "Cart ID and Product UID are required to remove a product."
      );
      throw new Error(
        "Cart ID and Product UID are required to remove a product."
      );
    }
    const endpoint = `/cart/${cartId}/`;
    const data = { products: [productUid] }; // Format body as expected by the API
    try {
      const response = await this.delete(endpoint, {
        data, // Pass the request body here
        requiresAuth: true,
      });
      return response;
    } catch (error) {
      console.error("Error removing product from cart:", error);
      throw error;
    }
  };

  // Update cart item quantity (generic function)
  updateCartItemQuantity = async (cartId, itemId, qty) => {
    if (!cartId || !itemId) {
      console.error("Cart ID and Item ID are required.");
      throw new Error("Cart ID and Item ID are required.");
    }
    const endpoint = `/cart/${cartId}/item/${itemId}/`;
    const data = { qty };
    return await this.put(endpoint, data, { requiresAuth: true });
  };

  // Increment cart item quantity by 1
  incrementCartItemQuantity = async (cartId, itemId) => {
    // Retrieve the current cart to get item details
    const cart = await this.viewCart(cartId);
    const item = cart.items.find((i) => i.uid === itemId);
    if (!item) {
      console.error("Cart item not found for increment.");
      throw new Error("Cart item not found.");
    }
    const newQty = item.qty + 1;
    return await this.updateCartItemQuantity(cartId, itemId, newQty);
  };

  // Decrement cart item quantity by 1 (removes the item if quantity becomes 0)
  // Decrement cart item quantity by 1 (removes the item if quantity becomes 0)
  decrementCartItemQuantity = async (cartId, itemId) => {
    // Retrieve the current cart to get item details
    const cart = await this.viewCart(cartId);
    const item = cart.items.find((i) => i.uid === itemId);
    if (!item) {
      console.error("Cart item not found for decrement.");
      throw new Error("Cart item not found.");
    }
    const newQty = item.qty - 1;
    if (newQty <= 0) {
      // If new quantity is 0, remove the item from the cart
      return await this.removeItemFromCart(cartId, item.product.uid);
    } else {
      return await this.updateCartItemQuantity(cartId, itemId, newQty);
    }
  };

  // Custom method to handle adding a product to a shop, creator, or thrifter-specific cart
  addProductToCart = async (product, qty = 1) => {
    try {
      // Step 1: Fetch all carts
      const cartsResponse = await this.listCarts();
      const carts = cartsResponse.results;

      let cartId = null;

      // Step 2: Identify the associated entity
      if (product.shop) {
        cartId = carts.find((cart) => cart.shop === product.shop.uid)?.uid;
      } else if (product.creator) {
        cartId = carts.find(
          (cart) => cart.creator === product.creator.uid
        )?.uid;
      } else if (product.thrifter) {
        cartId = carts.find(
          (cart) => cart.thrifter === product.thrifter.uid
        )?.uid;
      } else {
        console.error(
          "Product is not associated with any shop, creator, or thrifter."
        );
        throw new Error("Product must belong to a shop, creator, or thrifter.");
      }

      // Step 3: Create a cart if none exists
      if (!cartId) {
        const entity = product.shop
          ? { shop: product.shop.uid }
          : product.creator
          ? { creator: product.creator.uid }
          : { thrifter: product.thrifter.uid };

        const newCart = await this.createCart(entity);
        cartId = newCart.uid;
      }

      // Step 4: Add the product to the cart
      const addItemResponse = await this.addItemToCart(
        cartId,
        product.uid,
        qty
      );
      return addItemResponse;
    } catch (error) {
      console.error("Error adding product to cart:", error);
      throw error;
    }
  };

  // Delete a cart
  deleteCart = async (cartId) => {
    if (!cartId) {
      console.error("Cart ID is required to delete a cart.");
      throw new Error("Cart ID is required to delete a cart.");
    }
    const endpoint = `/cart/${cartId}/`;
    return await this.delete(endpoint, { requiresAuth: true });
  };

  // 🛒 Fetch checkout details (delivery fee, service fee, total)
  getCheckoutDetails = async (cartId, deliveryType = "PEC") => {
    if (!cartId) {
      console.error("Cart ID is required for checkout.");
      throw new Error("Cart ID is required for checkout.");
    }

    const endpoint = `/cart/checkout/${cartId}/delivery/${deliveryType}/`;
    try {
      const response = await this.get(endpoint, { requiresAuth: true });
      return response; // Contains { delivery, link, price, service_fee, total }
    } catch (error) {
      console.error("Error fetching checkout details:", error);
      throw error;
    }
  };

  // ✅ Proceed to checkout (redirect to payment link)
  proceedToCheckout = async (cartId, deliveryType = "PEC") => {
    try {
      const checkoutData = await this.getCheckoutDetails(cartId, deliveryType);
      if (checkoutData.link) {
        window.location.href = checkoutData.link; // Redirect user to payment gateway
      } else {
        console.error("Checkout link not received.");
        throw new Error("Failed to generate checkout link.");
      }
    } catch (error) {
      console.error("Error proceeding to checkout:", error);
      throw error;
    }
  };
}

export default CartService;
