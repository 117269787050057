import ApiService from "./ApiService";

class ProductService extends ApiService {

// PUBLIC ENDPOINT: Get all products (No authentication required)
getPublicProducts = async (params = {}) => {
  try {
    const q = this.createQueryParams(params);
    const response = await this.get(`/product/${q ? "?" + q : ""}`, {
      authRequired: false, 
    });
    return response;
  } catch (error) {
    console.error("Error fetching public products:", error);
    throw error;
  }
};

  // Get products associated with a specific shop by its UID
  getProducts = async (shopId, params = {}) => {
    const q = this.createQueryParams(params);
    return await this.get(`/product/?shop_uid=${shopId}${q ? "&" + q : ""}`, {
      authRequired: false,
    });
  };

  getAdminProductList = async (shopId) => {
    try {
      const response = await this.request(
        `/product/admin/${shopId}`,
        "GET",
        null,
        { requiresAuth: true }
      );
      return response;
    } catch (error) {
      console.error("Error fetching admin products:", error);
      throw error;
    }
  };


  /// SHOP OWNER ENDPOINTS

  ShopOwnerGetProductList = async (shopId) => {
    try {
      const response = await this.request(
        `/product/${shopId}`,
        "GET",
        null,
        { requiresAuth: false }
      );
      return response;
    } catch (error) {
      console.error("Error fetching admin products:", error);
      throw error;
    }
  };

  ShopOwnerAddProduct = async (productData) => {
    try {
      const response = await this.request(
        `/product/`,
        "POST",
        productData, 
        { requiresAuth: true } 
      );
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error;
    }
  };

  ShopOwnerUpdateProduct = async (productUid, updatedProductData) => {
    try {
      const response = await this.request(
        `/product/${productUid}`,  
        "PUT",                   
        updatedProductData,          
        { requiresAuth: true }  
      );
      return response.data;
    } catch (error) {
      console.error("Error updating product details:", error);
      throw error;
    }
  };

  ShopOwnerDeleteProduct = async (productUid) => {
    try {
      const response = await this.request(
        `/product/${productUid}`,  
        "DELETE",                
        null,                     
        { requiresAuth: true }    
      );
      return response.data;
    } catch (error) {
      console.error("Error deleting product:", error);
      throw error;
    }
  };

  ShopOwnerUploadProductImage = async (productUid, imageFile) => {
    const formData = new FormData();
    formData.append("image", imageFile); 
  
    try {
      const response = await this.request(
        `/product/${productUid}/image`,
        "POST", 
        formData,  
        { requiresAuth: true, contentType: 'multipart/form-data' }  
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading product image:", error);
      throw error;
    }
  };
  

  // SUPER USER ENDPOINTS

  // POST METHOD
  addProduct = async (productData) => {
    try {
      const response = await this.request(
        `/product/admin/`,
        "POST",
        productData,
        { requiresAuth: true }
      );
      return response;
    } catch (error) {
      console.error("Error adding product:", error);
      throw error;
    }
  };

  // PUT METHOD
  updateProduct = async (productUid, productData) => {
    try {
      const response = await this.request(
        `/product/admin/product/${productUid}/`,
        "PUT",
        productData,
        { requiresAuth: true }
      );
      return response;
    } catch (error) {
      console.error("Error updating product:", error);
      throw error;
    }
  };

  // DELETE METHOD
  deleteProduct = async (productUid) => {
    try {
      const response = await this.request(
        `/product/admin/product/${productUid}/`,
        "DELETE",
        null,
        { requiresAuth: true }
      );
      return response;
    } catch (error) {
      console.error("Error deleting product:", error);
      throw error;
    }
  };
}

export default ProductService;
