import ApiService from "./ApiService";

class ShopService extends ApiService {
  // Public Endpoints
  getShops = async (path, params = {}) => {
    const f = this.createQueryParams(params);
    return await this.get(`${path}${f ? "?" + f : ""}`, {
      authRequired: false,
    });
  };

  getShop = async (uid, params = {}) => {
    return this.get(`/shop/${uid}`, { authRequired: false });
  };

  async applyForShop(shopData) {
    try {
      const response = await this.post("/shop/apply", shopData, {
        requiresAuth: false,
      });
      return response;
    } catch (error) {
      console.error("Error applying for shop:", error);
      throw error;
    }
  }

  // ShopOwner Endpoints
  async getShopDetails(shopId) {
    try {
      const response = await this.request(`/shop/${shopId}`, "GET", null, {
        requiresAuth: true,
      });
      return response;
    } catch (error) {
      console.error("Error fetching shop details:", error);
      throw error;
    }
  }

  async updateShopDetails(shopId, formData) {
    try {
      const response = await this.put(`/shop/${shopId}/`, formData, {
        requiresAuth: true,
      });
      return response;
    } catch (error) {
      console.error("Error updating shop details:", error);
      throw error;
    }
  }

  // SuperUser Endpoints
  async getAdminShops() {
    try {
      const response = await this.request("/shop/admin", "GET", null, {
        requiresAuth: true,
      });
      return response;
    } catch (error) {
      console.error("Error fetching admin shops:", error);
      throw error;
    }
  }

  async updateShopSubscriptionAdmin(shopId, subscriptionPackageId) {
    try {
      const payload = { subscription_package: subscriptionPackageId };
      console.log("updateShopSubscriptionAdmin payload:", payload);
      const response = await this.patch(
        `/shop/admin/${shopId}/update/`,
        payload,
        { requiresAuth: true }
      );
      return response;
    } catch (error) {
      console.error("Error updating shop subscription:", error);
      throw error;
    }
  }
  
  async updateShopRankAdmin(shopId, newRank) {
    try {
      const payload = { rank: String(newRank) };
      console.log("updateShopRankAdmin payload:", payload);
      const response = await this.patch(
        `/shop/admin/${shopId}/update/`,
        payload,
        { requiresAuth: true }
      );
      return response;
    } catch (error) {
      console.error("Error updating shop rank:", error);
      throw error;
    }
  }
  
  async updateShopStatusAdmin(shopId, newStatus) {
    try {
      const payload = { approval_status: newStatus };
      console.log("updateShopStatusAdmin payload:", payload);
      const response = await this.patch(
        `/shop/admin/${shopId}/update/`,
        payload,
        { requiresAuth: true }
      );
      return response;
    } catch (error) {
      console.error("Error updating shop status:", error);
      throw error;
    }
  }

  // New methods for deletion and fetching associated products
  async deleteShop(shopId) {
    try {
      const response = await this.delete(`/shop/${shopId}`, { requiresAuth: true });
      return response;
    } catch (error) {
      console.error("Error deleting shop:", error);
      throw error;
    }
  }

  async fetchProductsData(shopId) {
    try {
      // Adjust this endpoint as needed to match your product API
      const response = await this.get(`/product/shop/${shopId}`, { requiresAuth: true });
      return response.results;
    } catch (error) {
      console.error("Error fetching products data:", error);
      throw error;
    }
  }
}

export default ShopService;
