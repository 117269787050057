import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { MdAdminPanelSettings } from "react-icons/md";
import UserService from "../../../services/UserService";
import AddressService from "../../../services/AddressService";
import logoImg from "../../../assets/logo.png";
import "./styles/account-management.css";
import { UserDetails } from "./UserDetails";
import { UserAddress } from "./UserAddress";
import { UserChangePassword } from "./UserChangePassword";
import { Loader } from "../../Common/Loader";

const userService = new UserService();
const addressService = new AddressService();

export const AccountManagement = () => {
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    groups: [],
    uid: "",
    extras: null,
  });
  const [originalData, setOriginalData] = useState({});
  const [editable, setEditable] = useState(false);

  const [addressData, setAddressData] = useState({
    street: "",
    suburb: "",
    city: "",
    postal_code: "",
    country: "South Africa",
  });
  const [originalAddressData, setOriginalAddressData] = useState({});
  const [addressEditable, setAddressEditable] = useState(false);

  useEffect(() => {
    fetchUserData();
    fetchAddressData();
  }, []);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await userService.currentUser();
      if (response && response.user) {
        const fullUserData = { ...response.user, extras: response.extras };
        setUserData(fullUserData);
        setOriginalData(fullUserData);
      } else {
        throw new Error("Failed to load user details.");
      }
    } catch (error) {
      toast.error(error.message || "Error loading user data");
    } finally {
      setLoading(false);
    }
  };

  const fetchAddressData = async () => {
    setLoading(true);
    try {
      const response = await addressService.getAddress();
      if (Array.isArray(response) && response.length > 0) {
        setAddressData(response[0]);
        setOriginalAddressData(response[0]);
      } else if (response && response.uid) {
        setAddressData(response);
        setOriginalAddressData(response);
      } else {
        console.warn("No address data found.");
      }
    } catch (error) {
      console.error("Could not fetch address:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAdminLink = () => {
    const groups = userData.groups;
    const role = groups && groups.length > 0 ? groups[0].name : null;
    if (role === "ShopOwner") {
      return userData.extras && userData.extras.uid
        ? `/admin/manage-shop/${userData.extras.uid}`
        : null;
    } else if (role === "Admin") {
      return "/admin/shops";
    } else if (role === "Creator" || role === "Thrifter") {
      return `/admin/creatives-hub-users/${userData.uid}`;
    }
    return null;
  };

  const adminLink = getAdminLink();

  return (
    <>
      <Helmet>
        <title>My Account</title>
      </Helmet>
      <div className="my-account-container">
        {loading && <Loader message="Loading..." />}
        <img src={logoImg} alt="Logo" className="logo" />
        <h1>My Account</h1>

        <div className="action-buttons">
          {adminLink && (
            <Link to={adminLink} className="admin-link">
              <MdAdminPanelSettings size={24} />
              <span>Go to Admin</span>
            </Link>
          )}
          <button
            className="see-orders-btn"
            onClick={() => {
              /* Future orders logic */
            }}
          >
            <span>See Orders</span>
          </button>
        </div>

        <div className="forms-container">
          <div className="profile-address-container">
            <UserDetails
              userData={userData}
              setUserData={setUserData}
              originalData={originalData}
              setOriginalData={setOriginalData}
              editable={editable}
              setEditable={setEditable}
              setLoading={setLoading}
            />
            <UserAddress
              addressData={addressData}
              setAddressData={setAddressData}
              originalAddressData={originalAddressData}
              setOriginalAddressData={setOriginalAddressData}
              addressEditable={addressEditable}
              setAddressEditable={setAddressEditable}
              setLoading={setLoading}
            />
          </div>
          <UserChangePassword />
        </div>
      </div>
    </>
  );
};
