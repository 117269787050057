import React from "react";
import { Link } from "react-router-dom";
import "./styles/creativeshub-markets-menu.css";

export const CreativesHubMarketsMenu = () => {
  
  return (
    <div className="flex-container">
      <div className="flex-slide home">
        <div className="flex-title flex-title-home">Thrift Market 101</div>
        <div className="flex-about flex-about-home">
          <Link to="/creatives-hub-thrift">Visit Thrift Market</Link>
          <p>Buy and sell second-hand creative clothing items. Discover unique pieces at amazing prices.</p>
        </div>
      </div>
      <div className="flex-slide creators">
        <div className="flex-title">The Creators Market</div>
        <div className="flex-about">
          <Link to="/creatives-hub-creators">Visit Creators Market</Link>
          <p>Explore live auctions from artistic, innovative creators from clothing, jewelry, paintings, to other creative designs.</p>
        </div>
      </div>
      <div className="flex-slide custom">
        <div className="flex-title">Request A Custom Made</div>
        <div className="flex-about">
          <Link to="/creatives-hub-tailor-made">Request Custom Made</Link>
          <p>Partner with our creative designers to request tailor-made clothing that matches your vision.</p>
        </div>
      </div>
      <div className="flex-slide join">
        <div className="flex-title">Join The Creatives Hub</div>
        <div className="flex-about">
          <Link to="/apply">Join Here</Link>
          <p>Explore a world of creativity, where you can bid, thrift, and request tailor-made designs.</p>
        </div>
      </div>
    </div>
  );
};
