import ApiService from "./ApiService";

class AuthService extends ApiService {
  constructor(baseURL) {
    super(baseURL);
    this.loginEndpoint = "/auth/login/";
    this.signupEndpoint = "/auth/signup/User/";
    this.updateUserEndpoint = "/user/";
    this.changePasswordEndpoint = "/auth/change-password/";
    this.accessTokenKey = "accessToken";
    this.refreshTokenKey = "refreshToken";
    this.requestResetEndpoint = "/auth/request-reset/";
  }

  isAuthenticated() {
    const token = this.getAccessToken();
    return !!token && !this.isTokenExpired(token);
  }

  getAccessToken() {
    return localStorage.getItem(this.accessTokenKey);
  }

  isTokenExpired(token) {
    try {
      const decoded = JSON.parse(atob(token.split(".")[1]));
      const now = Date.now() / 1000;
      return decoded.exp < now;
    } catch (e) {
      console.error("Failed to decode token:", e);
      return true;
    }
  }

  // POST - LOGIN USER
  async login(email, password) {
    try {
      const response = await this.request(
        this.loginEndpoint,
        "POST",
        { email, password },
        { requiresAuth: false }
      );

      if (response.access && response.refresh) {
        localStorage.setItem(this.accessTokenKey, response.access);
        localStorage.setItem(this.refreshTokenKey, response.refresh);
        localStorage.setItem("userEmail", email);

        const event = new Event("authChange");
        window.dispatchEvent(event);

        return response;
      } else {
        throw new Error("Wrong email or password");
      }
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  }

  // POST - SIGN UP USER
  async signUpUser(userData) {
    try {
      const response = await this.request(
        this.signupEndpoint,
        "POST",
        userData,
        { requiresAuth: false }
      );
      return response;
    } catch (error) {
      console.error("Sign Up error:", error);
      throw error;
    }
  }

  // PUT - UPDATE USER
  async updateUser(userData) {
    try {
      const response = await this.request(
        this.updateUserEndpoint,
        "PUT",
        userData,
        { requiresAuth: true }
      );
      return response;
    } catch (error) {
      console.error("Update User error:", error);
      throw error;
    }
  }

  // POST - LOG OUT USER
  async logout() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem("userEmail");

    const event = new Event("authChange");
    window.dispatchEvent(event);
  }

  // POST - REQUEST PASSWORD RESET
  async requestPasswordReset(email) {
    try {
      const formdata = new FormData();
      formdata.append("email", email);
  
      const response = await this.request(
        this.requestResetEndpoint,
        "POST",
        formdata,
        { requiresAuth: false }
      );
      console.log("Password reset response:", response);

      if (response.success === true) {
        return response;
      } else {
        throw new Error(response.message || "Failed to request password reset");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.detail ||
        error.message || 
        "An unknown error occurred.";
    
      console.error("Password Reset Request error:", errorMessage);
      throw new Error(errorMessage);
    }
    
  }

// POST - CHANGE PASSWORD
async changePassword(oldPassword, newPassword, confirmNewPassword) {
  try {
    const response = await this.request(
      this.changePasswordEndpoint,
      "POST",
      {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword
      },
      { requiresAuth: true }
    );
    return response;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.detail ||
      error.message ||
      "An unknown error occurred.";
    console.error("Change Password error:", errorMessage);
    throw new Error(errorMessage);
  }
}
}

export default AuthService;