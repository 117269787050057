import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useNavigate, Link } from "react-router-dom";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import AuthService from "../../services/AuthService";
import logoImg from "../../assets/logo.png";
import "./styles/signup.css";
import { Loader } from "../Common/Loader";

const authService = new AuthService();

export const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    username: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSignUp = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      await authService.signUpUser(userData);
      toast.success("Account created successfully. You can now log in!");
      navigate("/login");
    } catch (error) {
      console.error("SignUp error:", error.message);
      toast.error(error.message || "Failed to sign up. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const { email, username, password1, password2, first_name, last_name } = userData;

    if (!email || !username || !password1 || !password2 || !first_name || !last_name) {
      toast.error("All fields are required.");
      return false;
    }
    if (password1 !== password2) {
      toast.error("Passwords do not match.");
      return false;
    }
    return true;
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <div className="signup-container">
        {loading && <Loader message="Creating your account..." />}
        <img src={logoImg} alt="Logo" className="logo" />
        <h1>Sign Up</h1>
        <p className="signup-description">
          Create an account to access the Creatives Hub. Fill in all details to get started.
        </p>
        <input
          type="text"
          name="first_name"
          placeholder="First Name"
          value={userData.first_name}
          onChange={handleChange}
          aria-label="First Name"
        />
        <input
          type="text"
          name="last_name"
          placeholder="Last Name"
          value={userData.last_name}
          onChange={handleChange}
          aria-label="Last Name"
        />
        <input
          type="text"
          name="username"
          placeholder="Phone Number"
          value={userData.username}
          onChange={handleChange}
          aria-label="Username"
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={userData.email}
          onChange={handleChange}
          aria-label="Email"
        />
        <div className="password-input">
          <input
            type={showPassword ? "text" : "password"}
            name="password1"
            placeholder="Password"
            value={userData.password1}
            onChange={handleChange}
            aria-label="Password"
          />
          {showPassword ? (
            <RiEyeOffLine
              onClick={togglePasswordVisibility}
              aria-label="Hide Password"
              className="password-toggle-icon"
            />
          ) : (
            <RiEyeLine
              onClick={togglePasswordVisibility}
              aria-label="Show Password"
              className="password-toggle-icon"
            />
          )}
        </div>
        <div className="password-input">
          <input
            type={showPassword ? "text" : "password"}
            name="password2"
            placeholder="Confirm Password"
            value={userData.password2}
            onChange={handleChange}
            aria-label="Confirm Password"
          />
          {showPassword ? (
            <RiEyeOffLine
              onClick={togglePasswordVisibility}
              aria-label="Hide Password"
              className="password-toggle-icon"
            />
          ) : (
            <RiEyeLine
              onClick={togglePasswordVisibility}
              aria-label="Show Password"
              className="password-toggle-icon"
            />
          )}
        </div>
        <button onClick={handleSignUp} disabled={loading}>
          Sign Up
        </button>

        <div className="inline-links">
          <Link to="/login">Already have an account? Login</Link>
        </div>
      </div>
    </>
  );
};
