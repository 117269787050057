import React, { useState } from "react";
import { toast } from "react-toastify";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import AuthService from "../../../services/AuthService";
import "./styles/user-change-password.css";

const authService = new AuthService();

export const UserChangePassword = () => {
  const [expanded, setExpanded] = useState(false);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [loading, setLoading] = useState(false);

  // States to toggle password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = async () => {
    if (passwords.newPassword !== passwords.confirmNewPassword) {
      toast.error("New passwords do not match");
      return;
    }
    setLoading(true);
    try {
      await authService.changePassword(
        passwords.oldPassword,
        passwords.newPassword,
        passwords.confirmNewPassword
      );
      toast.success("Password changed successfully!");
      setPasswords({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
      setExpanded(false);
    } catch (error) {
      toast.error(error.message || "Failed to change password.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="change-password-container">
      <button className="toggle-button" onClick={() => setExpanded(!expanded)}>
        Change Password
      </button>

      {expanded && (
        <div className="password-form">
          <label>Old Password</label>
          <div className="password-input">
            <input
              type={showOldPassword ? "text" : "password"}
              name="oldPassword"
              value={passwords.oldPassword}
              onChange={handleChange}
            />
            {showOldPassword ? (
              <RiEyeOffLine
                onClick={() => setShowOldPassword(false)}
                aria-label="Hide Password"
                className="password-toggle-icon"
              />
            ) : (
              <RiEyeLine
                onClick={() => setShowOldPassword(true)}
                aria-label="Show Password"
                className="password-toggle-icon"
              />
            )}
          </div>

          <label>New Password</label>
          <div className="password-input">
            <input
              type={showNewPassword ? "text" : "password"}
              name="newPassword"
              value={passwords.newPassword}
              onChange={handleChange}
            />
            {showNewPassword ? (
              <RiEyeOffLine
                onClick={() => setShowNewPassword(false)}
                aria-label="Hide Password"
                className="password-toggle-icon"
              />
            ) : (
              <RiEyeLine
                onClick={() => setShowNewPassword(true)}
                aria-label="Show Password"
                className="password-toggle-icon"
              />
            )}
          </div>

          <label>Confirm New Password</label>
          <div className="password-input">
            <input
              type={showConfirmNewPassword ? "text" : "password"}
              name="confirmNewPassword"
              value={passwords.confirmNewPassword}
              onChange={handleChange}
            />
            {showConfirmNewPassword ? (
              <RiEyeOffLine
                onClick={() => setShowConfirmNewPassword(false)}
                aria-label="Hide Password"
                className="password-toggle-icon"
              />
            ) : (
              <RiEyeLine
                onClick={() => setShowConfirmNewPassword(true)}
                aria-label="Show Password"
                className="password-toggle-icon"
              />
            )}
          </div>

          <button
            className="change-password-button"
            onClick={handlePasswordChange}
            disabled={loading}
          >
            {loading ? "Changing..." : "Change Password"}
          </button>
        </div>
      )}
    </div>
  );
};
