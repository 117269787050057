import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles/signup-form-options.css";

export const SignUpFormOptions = ({ onSelectOption }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="signup-form-options">
      <div className="options-content">
        <h2>Congratulations on Taking the First Step!</h2>
        <p>
          Welcome to Creatives SA, where your creative journey begins! By
          choosing to sign up, you’ve joined a vibrant community of artists,
          artisans, and innovators. At Creatives SA, we believe in empowering
          creatives like you to bring your unique vision to life in a space that
          celebrates artistry and individuality.
        </p>
        <p>
          <strong>
            Before applying for a shop or joining the Creatives Hub, you need to
            have an account. 
          </strong><br/>
          Creating an account gives you access to apply for your own shop or
          join a dynamic marketplace as a Creator or Thrifter.
        </p>
        <p>
          Creatives SA offers two distinct ways to showcase your creativity:
        </p>
        <ul>
          <li>
            Apply for a Shop: Customize, showcase, and sell
            your unique products in your own independent shop.
          </li>
          <li>
            Join the Creatives Hub: Choose from two markets—The
            Creators Market for auctions or fixed-price items (clothing,
            jewelry, artwork), and Thrift Market 101 for second-hand creative
            clothing.
          </li>
        </ul>
        <p>
          Creatives SA is more than just a platform; it’s a celebration of
          creativity, individuality, and endless possibilities. Take a moment to
          explore your options and choose the path that aligns with your
          aspirations.
          <br/><strong>Your journey starts here.</strong>
        </p>
      </div>
      <div className="options-buttons">
        <h3>Choose an Option:</h3>

        <Link to="/sign-up">
          <button type="button" className="create-account-btn">
            Create Account
          </button>
        </Link>

        <Link to="/shop-applications">
          <button type="button">Apply for a Shop</button>
        </Link>

        <Link to="/creatives-applications">
          <button type="button">Join The Creatives Hub</button>
        </Link>

        <div className="login-link">
          <Link to="/login">Already have an Account?</Link>
        </div>
      </div>
    </div>
  );
};
