import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminDashboard } from "./AdminDashboard";
import { AdminHandleReviews } from "./AdminHandleReviews";
import ShopService from "../../../services/ShopService";
import "./styles/admin.css";

const shopService = new ShopService();

export const AdminManageShopReviews = () => {
  const { shop_id } = useParams(); // Ensure `shop_id` is coming from the URL params
  const [shopDetails, setShopDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const shopData = await shopService.getShopDetails(shop_id);
        if (!shopData) {
          throw new Error("Shop data not found");
        }
        setShopDetails(shopData);
      } catch (err) {
        console.error("Error fetching shop details:", err.message);
        setError("Error fetching shop details. Please try again later.");
      }
    };

    if (shop_id) fetchShopData();
  }, [shop_id]);

  if (error) {
    return (
      <div className="admin-shop-details">
        <AdminDashboard />
        <p className="error-message">{error}</p>
      </div>
    );
  }

  return (
    <div className="admin-shop-details">
      <AdminDashboard />
      {shopDetails ? (
        <div>
          <h2>MANAGE REVIEWS FOR: {shopDetails.name}</h2>
          <p>
            <strong>Shop Name:</strong> {shopDetails.name}
          </p>
          <p>
            <strong>Owner Name:</strong> {shopDetails.owner_name}
          </p>
          <p>
            <strong>Owner Email:</strong> {shopDetails.owner_email}
          </p>
          <p>
            <strong>Owner Phone:</strong> {shopDetails.owner_phone}
          </p>
          <AdminHandleReviews shop_id={shop_id} />
        </div>
      ) : (
        <p>Loading shop details...</p>
      )}
    </div>
  );
};
