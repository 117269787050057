import React, { useState } from "react";
import { toast } from "react-toastify";
import ReviewService from "../../services/ReviewService";
import "./styles/review-form.css";

const reviewService = new ReviewService();

export const ReviewForm = ({ shopId, onAddReview }) => {
  // Only need to collect review content and rating.
  const [reviewData, setReviewData] = useState({
    content: "",
    rating: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReviewData((prevReview) => ({
      ...prevReview,
      [name]: value,
    }));
  };

  const handleSubmitReview = async () => {
    try {
      // Attempt to add the review (requires auth)
      await reviewService.addReview(shopId, reviewData);
      onAddReview(reviewData);
      setReviewData({ content: "", rating: 0 });
      // Show success toast message
      toast.success("Review added successfully!");
    } catch (error) {
      console.error("Error submitting review:", error);
      // Show error toast message
      toast.error("Failed to add review. Please try again.");
    }
  };

  return (
    <div className="review-form-section">
      <h3>Add New Review</h3>
      <div className="review-form">
        <div className="form-group">
          <label>
            Rating:
            <select
              id="rating"
              name="rating"
              value={reviewData.rating}
              onChange={handleInputChange}
              required
            >
              <option value="0" disabled>
                Select a rating
              </option>
              <option value="1">1 - Poor</option>
              <option value="2">2 - Fair</option>
              <option value="3">3 - Average</option>
              <option value="4">4 - Good</option>
              <option value="5">5 - Excellent</option>
            </select>
          </label>
        </div>
        <br />
        <div className="form-group">
          <label>
            Review Text:
            <textarea
              name="content"
              value={reviewData.content}
              onChange={handleInputChange}
              required
            ></textarea>
          </label>
        </div>
        <br />
        <button type="button" onClick={handleSubmitReview}>
          Submit Review
        </button>
      </div>
    </div>
  );
};
