import React from "react";
import { Link } from "react-router-dom";
import { HashLink as ScrollLink } from "react-router-hash-link";
import "./styles/header.css";
import { RiShoppingBagLine } from "react-icons/ri";

export const Header = () => {

  return (
    <div  className="header-section">
      <div className="header-text">
        <h1>The Home Of Creativity</h1>
        <p>Where creatives meet the fashion adventurer</p>
        <ScrollLink smooth to="/#shops">
          <button>
            Shops <i><RiShoppingBagLine /></i>
          </button>
        </ScrollLink>
      </div>
      <div className="header-buttons">
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Thrift Market 101</button>
        </ScrollLink>
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Designer Auction</button>
        </ScrollLink>
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Request a Tailor-Made</button>
        </ScrollLink>
        <Link to="/apply">
          <button>Apply For a Shop</button>
        </Link>
      </div>
    </div>
  );
};
