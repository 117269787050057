import React from "react";
import { toast } from "react-toastify";
import AddressService from "../../../services/AddressService";
import "./styles/user-address.css";

const addressService = new AddressService();

export const UserAddress = ({ 
  addressData, 
  setAddressData, 
  originalAddressData, 
  setOriginalAddressData, 
  addressEditable, 
  setAddressEditable, 
  setLoading 
}) => {
  
  const handleAddressChange = (e) => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  const handleSaveAddress = async () => {
    if (!addressEditable) return;
    setLoading(true);

    try {
      const updatedFields = {};
      Object.keys(addressData).forEach((key) => {
        if (addressData[key] !== originalAddressData[key]) {
          updatedFields[key] = addressData[key];
        }
      });

      if (Object.keys(updatedFields).length === 0) {
        toast.info("No changes detected.");
        setAddressEditable(false);
        return;
      }

      if (originalAddressData.uid) {
        await addressService.updateAddress(originalAddressData.uid, updatedFields);
        toast.success("Address updated successfully!");
      } else {
        const createResponse = await addressService.createAddress(addressData);
        toast.success("Address created successfully!");
        if (createResponse?.uid) {
          setAddressData(createResponse);
          setOriginalAddressData(createResponse);
        }
      }

      setAddressEditable(false);
      setOriginalAddressData(addressData);
    } catch (error) {
      toast.error(error.message || "Failed to update address.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="address-form">
      <h2>Your Address</h2>

      <label>Street</label>
      <input 
        type="text" 
        name="street" 
        value={addressData.street} 
        onChange={handleAddressChange} 
        disabled={!addressEditable} 
      />

      <label>Suburb</label>
      <input 
        type="text" 
        name="suburb" 
        value={addressData.suburb} 
        onChange={handleAddressChange} 
        disabled={!addressEditable} 
      />

      <label>City</label>
      <input 
        type="text" 
        name="city" 
        value={addressData.city} 
        onChange={handleAddressChange} 
        disabled={!addressEditable} 
      />

      <label>Postal Code</label>
      <input 
        type="text" 
        name="postal_code" 
        value={addressData.postal_code} 
        onChange={handleAddressChange} 
        disabled={!addressEditable} 
      />

      <label>Country</label>
      <input 
        type="text" 
        name="country" 
        value="South Africa" 
        disabled 
      />

      {!addressEditable ? (
        <button onClick={() => setAddressEditable(true)}>Edit Address</button>
      ) : (
        <button onClick={handleSaveAddress}>Save Address</button>
      )}
    </div>
  );
};
