import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AdminDashboard } from "./AdminDashboard";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import CreativesHubService from "../../../services/CreativesHubService";
import "./styles/admin-shop-list.css";

const creativesHubService = new CreativesHubService();

export const AdminOurUsers = () => {
  const [ourUsers, setOurUsers] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await creativesHubService.getAdminCreativesHubUsers();
        if (!response || !response.results || response.results.length === 0) {
          throw new Error("No data returned from server");
        }

        // Only show users in the "User" group
        const filteredUsers = response.results.filter((user) =>
          user.groups.some((group) => group.name === "User")
        );
        setOurUsers(filteredUsers);
      } catch (err) {
        console.error("Error fetching Our Users:", err.message);
        setError("Error fetching Our Users. Please try again later.");
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteUser = (userId) => {
    setUserToDelete(userId);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const success = await creativesHubService.deleteUser(userToDelete);
      if (success) {
        setOurUsers((prev) => prev.filter((user) => user.uid !== userToDelete));
        toast.success("User deleted successfully!");
      } else {
        setError("Failed to delete user. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to delete user. Please try again later.");
    } finally {
      setUserToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelDelete = () => {
    setUserToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleStatusToggle = async (userId, newStatus) => {
    try {
      const success = await creativesHubService.updateUserStatus(userId, newStatus);
      if (success) {
        toast.success("User status updated successfully!");
        setOurUsers((prev) =>
          prev.map((user) =>
            user.uid === userId
              ? { ...user, is_active: newStatus === "active" }
              : user
          )
        );
      } else {
        setError("Failed to update user status. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to update user status. Please try again later.");
    }
  };

  // Filter users based on search and status
  const filteredUsers = ourUsers.filter((user) => {
    const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
    const matchesSearch =
      fullName.includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus =
      statusFilter === "active"
        ? user.is_active
        : statusFilter === "inactive"
        ? !user.is_active
        : true;
    return matchesSearch && matchesStatus;
  });

  return (
    <div className="admin-shop-list">
      <Helmet>
        <title>Creatives SA - Manage Our Users</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Our Users</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name or email..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {searchQuery && (
          <button onClick={() => setSearchQuery("")}>Clear Result(s)</button>
        )}
      </div>

      {/* Only Status Filter */}
      <div className="filter-container">
        <label>
          Status:
          <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="">All</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </label>
      </div>

      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          {showConfirmationModal && (
            <ConfirmationModal
              message="Are you sure you want to delete this user?"
              onConfirm={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Address</th>
                <th>User Group</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.uid}>
                  <td>{user.first_name || "-"}</td>
                  <td>{user.last_name || "-"}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.groups[0]?.name || "No Group"}
                  </td>
                  <td>
                    <label className="toggle-label">
                      <input
                        type="checkbox"
                        checked={user.is_active}
                        onChange={(e) =>
                          handleStatusToggle(
                            user.uid,
                            e.target.checked ? "active" : "inactive"
                          )
                        }
                      />
                      <span className="back">
                        <span className="toggle"></span>
                        <span className="label on">Active</span>
                        <span className="label off">Inactive</span>
                      </span>
                    </label>
                  </td>
                  <td>
                    <Link
                      to={`/admin/our-users/${user.uid}`}
                      className="button-link"
                    >
                      View Details
                    </Link>{" "}
                    <button onClick={() => handleDeleteUser(user.uid)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
              {filteredUsers.length === 0 && (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    No users match the current filters.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
