import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AdminDashboard } from "./AdminDashboard";
import ShopService from "../../../services/ShopService";
import { toast } from "react-toastify";
import "./styles/admin-manage-shop-applications.css";

const shopService = new ShopService();

export const AdminManageShopApplications = () => {
  const [shops, setShops] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [subscriptionFilter, setSubscriptionFilter] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await shopService.getAdminShops();
        if (!response || !response.results || response.results.length === 0) {
          throw new Error("No data returned from server");
        }
        const applicationShops = response.results.filter(
          (shop) =>
            shop.approval_status === "application" ||
            shop.approval_status === "pending"
        );
        setShops(applicationShops);
      } catch (err) {
        console.error("Error fetching shops:", err.message);
        setError("Error fetching shop applications. Please try again later.");
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleUpdateStatus = async (shopId, newStatus) => {
    try {
      await shopService.updateShopStatusAdmin(shopId, newStatus);
      setShops((prevShops) =>
        prevShops.map((shop) =>
          shop.uid === shopId ? { ...shop, approval_status: newStatus } : shop
        )
      );
      toast.success("Shop status updated successfully");
    } catch (error) {
      console.error("Error updating shop status:", error);
      setError("Error updating shop status. Please try again later.");
    }
  };

  // Refined search and filters
  const filteredShops = shops.filter((shop) => {
    const nameMatch =
      shop.name && shop.name.toLowerCase().includes(searchTerm);
    const ownerNameMatch =
      shop.owner_name && shop.owner_name.toLowerCase().includes(searchTerm);
    const ownerEmailMatch =
      shop.owner_email && shop.owner_email.toLowerCase().includes(searchTerm);
    const subscriptionMatch =
      shop.subscription_package &&
      shop.subscription_package.package_name &&
      shop.subscription_package.package_name.toLowerCase().includes(searchTerm);
    const searchMatch =
      nameMatch || ownerNameMatch || ownerEmailMatch || subscriptionMatch;

    const statusMatch = statusFilter
      ? shop.approval_status === statusFilter
      : true;

    const subscriptionFilterMatch = subscriptionFilter
      ? shop.subscription_package &&
        shop.subscription_package.package_name.toLowerCase() ===
          subscriptionFilter.toLowerCase()
      : true;

    return searchMatch && statusMatch && subscriptionFilterMatch;
  });

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const formatAddress = (address) => {
    if (!address) return "No Address";
    const { street, suburb, city, postal_code, country } = address;
    return `${street || ""}, ${suburb || ""}, ${city || ""}, ${postal_code || ""}, ${country || ""}`;
  };

  return (
    <div className="admin-manage-shop-applications">
      <Helmet>
        <title>Manage Shop Applications</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Shop Applications</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search by shop name, owner, email, or subscription..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        {searchTerm && (
          <button onClick={handleClearSearch} className="clear-btn">
            Clear
          </button>
        )}
      </div>

      {/* Filter container with Status and Subscription filters */}
      <div className="filter-container">
        <label>
          Status:
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="application">Application</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="inactive">Inactive</option>
            <option value="declined">Declined</option>
          </select>
        </label>
        <label>
          Subscription:
          <select
            value={subscriptionFilter}
            onChange={(e) => setSubscriptionFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="Basic">Basic</option>
            <option value="Gold">Gold</option>
            <option value="Platinum">Platinum</option>
          </select>
        </label>
      </div>

      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Shop Name</th>
              <th>Email</th>
              <th>Address</th>
              <th>Date Applied</th>
              <th>Status</th>
              <th>Subscription</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredShops.map((shop) => (
              <tr key={shop.uid}>
                <td>{shop.name}</td>
                <td>{shop.owner_email}</td>
                <td>{formatAddress(shop.address)}</td>
                <td>{formatDate(shop.date_applied)}</td>
                <td>
                  {shop.approval_status
                    ? shop.approval_status.charAt(0).toUpperCase() +
                      shop.approval_status.slice(1)
                    : "N/A"}
                </td>
                <td>
                  {shop.subscription_package &&
                  shop.subscription_package.package_name
                    ? shop.subscription_package.package_name
                    : "No Subscription"}
                </td>
                <td>
                  <button
                    className="action-btn"
                    onClick={() => handleUpdateStatus(shop.uid, "pending")}
                  >
                    Pending
                  </button>
                  <button
                    className="action-btn"
                    onClick={() => handleUpdateStatus(shop.uid, "approved")}
                  >
                    Approved
                  </button>
                  <button
                    className="action-btn decline-btn"
                    onClick={() => handleUpdateStatus(shop.uid, "declined")}
                  >
                    Decline
                  </button>
                </td>
              </tr>
            ))}
            {filteredShops.length === 0 && (
              <tr>
                <td colSpan="7" style={{ textAlign: "center" }}>
                  No shop applications match the current filters.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};
