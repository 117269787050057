import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AdminDashboard } from "./AdminDashboard";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import AdminEditRankModal from "./AdminEditRankModal";
import AdminEditShopSubscriptionModal from "./AdminEditShopSubscriptionModal";
import ShopService from "../../../services/ShopService";
import SubscriptionService from "../../../services/BlogService";
import "./styles/admin-shop-list.css";

const shopService = new ShopService();
const subscriptionService = new SubscriptionService();

export const AdminShopList = () => {
  const [shops, setShops] = useState([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // New filter states
  const [statusFilter, setStatusFilter] = useState("");
  const [rankFilter, setRankFilter] = useState("");
  const [subscriptionFilter, setSubscriptionFilter] = useState("");
  const [shopToDelete, setShopToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRankModal, setShowRankModal] = useState(false);
  const [selectedShopForRank, setSelectedShopForRank] = useState(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [selectedShopForSubscription, setSelectedShopForSubscription] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await shopService.getAdminShops();
        if (!response || !response.results || response.results.length === 0) {
          throw new Error("No data returned from server");
        }
        // Only include shops with approved or inactive status
        const filteredShops = response.results.filter(
          (shop) =>
            shop.approval_status === "approved" ||
            shop.approval_status === "inactive"
        );
        setShops(filteredShops);
      } catch (err) {
        console.error("Error fetching shops:", err.message);
        setError("Error fetching shops. Please try again later.");
      }
    };

    const fetchSubscriptionPackages = async () => {
      try {
        const data = await subscriptionService.getBlogs("/shop/subscription", {});
        setSubscriptionPackages(data.results || []);
      } catch (err) {
        console.error("Error fetching subscription packages:", err.message);
      }
    };

    fetchData();
    fetchSubscriptionPackages();
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteShop = async (shopId) => {
    setShopToDelete(shopId);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const products = await shopService.fetchProductsData(shopToDelete);
      if (products.length > 0) {
        toast.error(
          "Cannot delete shop. There are products associated with this shop."
        );
        setShowConfirmationModal(false);
        return;
      }
      const success = await shopService.deleteShop(shopToDelete);
      if (success) {
        setShops((prevShops) =>
          prevShops.filter((shop) => shop.uid !== shopToDelete)
        );
        toast.success("Shop deleted successfully!");
      } else {
        setError("Failed to delete shop. Please try again later.");
      }
    } catch (err) {
      console.error("Error deleting shop:", err.message);
      setError("Failed to delete shop. Please try again later.");
    } finally {
      setShopToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelDelete = () => {
    setShopToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleStatusToggle = async (shopId, newStatus) => {
    try {
      const success = await shopService.updateShopStatusAdmin(shopId, newStatus);
      if (success) {
        toast.success("Shop status updated successfully!");
        setShops((prevShops) =>
          prevShops.map((shop) =>
            shop.uid === shopId ? { ...shop, approval_status: newStatus } : shop
          )
        );
      } else {
        setError("Failed to update shop status. Please try again later.");
      }
    } catch (err) {
      console.error("Error updating shop status:", err.message);
      setError("Failed to update shop status. Please try again later.");
    }
  };

  const handleEditShopRank = (shopId, currentRank) => {
    setSelectedShopForRank(shopId);
    setShowRankModal(true);
  };

  const handleSaveRankChanges = async (newRank) => {
    try {
      const success = await shopService.updateShopRankAdmin(
        selectedShopForRank,
        newRank
      );
      if (success) {
        toast.success("Shop rank updated successfully!");
        setShops((prevShops) =>
          prevShops.map((shop) =>
            shop.uid === selectedShopForRank ? { ...shop, rank: newRank } : shop
          )
        );
      } else {
        setError("Failed to update shop rank. Please try again later.");
      }
    } catch (err) {
      console.error("Error updating shop rank:", err.message);
      setError("Failed to update shop rank. Please try again later.");
    } finally {
      setShowRankModal(false);
      setSelectedShopForRank(null);
    }
  };

  const handleEditShopSubscription = (shopId, currentSubscription) => {
    setSelectedShopForSubscription(shopId);
    setShowSubscriptionModal(true);
  };

  const handleSaveSubscriptionChanges = async (newSubscription = null) => {
    if (!newSubscription) {
      toast.error("Please select a subscription package or cancel");
      return;
    }
    // Optimistically update UI
    setShops((prevShops) =>
      prevShops.map((shop) =>
        shop.uid === selectedShopForSubscription
          ? { ...shop, subscription_package: { package_name: "...updating" } }
          : shop
      )
    );

    try {
      const success = await shopService.updateShopSubscriptionAdmin(
        selectedShopForSubscription,
        newSubscription
      );
      if (success) {
        toast.success("Shop subscription updated successfully!");
        setShops((prevShops) =>
          prevShops.map((shop) =>
            shop.uid === selectedShopForSubscription
              ? {
                  ...shop,
                  subscription_package: {
                    uid: newSubscription,
                    package_name: getPackageNameById(newSubscription),
                  },
                }
              : shop
          )
        );
      } else {
        toast.error("Failed to update shop subscription. Please try again later.");
      }
    } catch (err) {
      console.error("Error updating shop subscription:", err.message);
      toast.error("Failed to update shop subscription. Please try again later.");
    } finally {
      setShowSubscriptionModal(false);
      setSelectedShopForSubscription(null);
    }
  };

  const getPackageNameById = (packageId) => {
    const pkg = subscriptionPackages.find(
      (p) => (p.uid || p.subscription_package_id) === packageId
    );
    return pkg ? pkg.package_name : "No Subscription";
  };

  // Filter shops using updated logic
  const filteredShops = shops.filter((shop) => {
    // Convert address object to a string for filtering
    const addressString = shop.address
      ? `${shop.address.street || ""} ${shop.address.suburb || ""} ${shop.address.city || ""} ${shop.address.postal_code || ""} ${shop.address.country || ""}`
      : "";
    const matchesAddress = addressString.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesSearch =
      (shop.name && shop.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (shop.owner_email && shop.owner_email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (shop.owner_phone && shop.owner_phone.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (shop.owner_name && shop.owner_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      matchesAddress;

    const matchesStatus = statusFilter ? shop.approval_status === statusFilter : true;
    // Convert both shop.rank and rankFilter to numbers for comparison
    const matchesRank = rankFilter ? parseInt(shop.rank, 10) === parseInt(rankFilter, 10) : true;
    const matchesSubscription = subscriptionFilter
      ? shop.subscription_package &&
        shop.subscription_package.package_name.toLowerCase() === subscriptionFilter.toLowerCase()
      : true;

    return matchesSearch && matchesStatus && matchesRank && matchesSubscription;
  });

  return (
    <div className="admin-shop-list">
      <Helmet>
        <title>Creatives SA - Manage Shops</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Shops</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name, email, phone, address etc"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {searchQuery && (
          <button onClick={() => setSearchQuery("")}>Clear Result(s)</button>
        )}
      </div>

      {/* Filter functionality */}
      <div className="filter-container">
        <label>
          Status:
          <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <option value="">All</option>
            <option value="approved">Approved</option>
            <option value="inactive">Inactive</option>
          </select>
        </label>
        <label>
          Rank:
          <select value={rankFilter} onChange={(e) => setRankFilter(e.target.value)}>
            <option value="">All</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </label>
        <label>
          Subscription:
          <select
            value={subscriptionFilter}
            onChange={(e) => setSubscriptionFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="basic">Basic</option>
            <option value="gold">Gold</option>
            <option value="platinum">Platinum</option>
          </select>
        </label>
      </div>

      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          {showConfirmationModal && (
            <ConfirmationModal
              message="Are you sure you want to delete this shop?"
              onConfirm={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {showRankModal && (
            <AdminEditRankModal
              initialRank={
                filteredShops.find((shop) => shop.uid === selectedShopForRank)?.rank || ""
              }
              onSave={handleSaveRankChanges}
              onClose={() => setShowRankModal(false)}
              onCancel={() => setShowRankModal(false)}
            />
          )}
          {showSubscriptionModal && (
            <AdminEditShopSubscriptionModal
              initialSubscription={
                filteredShops.find((shop) => shop.uid === selectedShopForSubscription)
                  ?.subscription_package?.uid || ""
              }
              onSave={handleSaveSubscriptionChanges}
              onClose={() => setShowSubscriptionModal(false)}
              onCancel={() => setShowSubscriptionModal(false)}
              subscriptionPackages={subscriptionPackages}
            />
          )}
          <table>
            <thead>
              <tr>
                <th>Shop Name</th>
                <th>Shop Owner</th>
                <th>Shop Number</th>
                <th>Shop Email</th>
                <th>Shop Address</th>
                <th>Shop Rank</th>
                <th>Shop Subscription</th>
                <th>Shop Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredShops.map((shop) => (
                <tr key={shop.uid}>
                  <td>{shop.name}</td>
                  <td>{shop.owner_name}</td>
                  <td>{shop.owner_phone}</td>
                  <td>{shop.owner_email}</td>
                  <td>
                    {shop.address
                      ? `${shop.address.street}, ${shop.address.suburb}, ${shop.address.city}, ${shop.address.postal_code}, ${shop.address.country}`
                      : "No Address"}
                  </td>
                  <td>
                    <button
                      className="shop-rank-btn"
                      onClick={() => handleEditShopRank(shop.uid, shop.rank)}
                    >
                      {shop.rank}
                    </button>
                  </td>
                  <td>
                    <button
                      className="subscription-package-btn"
                      onClick={() =>
                        handleEditShopSubscription(
                          shop.uid,
                          shop.subscription_package?.package_name
                        )
                      }
                    >
                      {shop.subscription_package
                        ? shop.subscription_package.package_name
                        : "No Subscription"}
                    </button>
                  </td>
                  <td>
                    <label className="toggle-label">
                      <input
                        type="checkbox"
                        checked={shop.approval_status === "approved"}
                        onChange={(e) =>
                          handleStatusToggle(
                            shop.uid,
                            e.target.checked ? "approved" : "inactive"
                          )
                        }
                      />
                      <span className="back">
                        <span className="toggle"></span>
                        <span className="label on"></span>
                        <span className="label off"></span>
                      </span>
                    </label>
                  </td>
                  <td>
                    <Link to={`/admin/shops/${shop.uid}`} className="button-link">
                      View Details
                    </Link>{" "}
                    <button onClick={() => handleDeleteShop(shop.uid)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
