import ApiService from "./ApiService";

class UserService extends ApiService {  
  
  // GET - CURRENT USER
  async currentUser() {
    try {
      const response = await this.request("/user/current-user/", "GET", null, {
        requiresAuth: true,
      });
      return response;
    } catch (error) {
      console.error("Get Current User error:", error);
      throw error;
    }
  }
  

  // PUT - Admin Update User Status
  async updateUserStatus(userId, newStatus) {
    try {
      const response = await this.request(`/admin/user/${userId}/status/`, 'PUT', { status: newStatus }, {
        requiresAuth: true,
      });
      return response;
    } catch (error) {
      console.error("Error updating user status:", error);
      return false;
    }
  }
}

export default UserService;