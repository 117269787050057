import React, { useEffect, useRef } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { IoIosWarning } from "react-icons/io";
import "./styles/login-required-modal.css";

const LoginRequiredModal = ({ isOpen, closeModal }) => {
  const closeButtonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [isOpen]);

  const handleLogin = () => {
    closeModal();
    navigate("/login");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Login Required"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000, 
        },
        content: {
          maxWidth: "400px",
          margin: "auto",
          padding: "20px",
          borderRadius: "8px",
          textAlign: "center",
          maxHeight: "30vh", 
          overflowY: "auto",
        },
      }}
    >
      <div className="modal-icon">
        <IoIosWarning size={40} color="#ffcc00" />
      </div>
      <h2 className="modal-title">Login Required</h2>
      <p className="modal-message">
        You must be logged in to perform this action.
      </p>
      <div className="modal-buttons">
        <button className="modal-button login-button" onClick={handleLogin}>
          Login Now
        </button>
        <button
          className="modal-button"
          ref={closeButtonRef}
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default LoginRequiredModal;
