import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CartService from "../../services/CartService";
import { toast } from "react-toastify";
import "./styles/checkout.css";

export const Checkout = () => {
  const { cartId, deliveryType = "PEC" } = useParams();
  const [checkoutDetails, setCheckoutDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedDelivery, setSelectedDelivery] = useState(deliveryType);
  const [paymentLink, setPaymentLink] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCheckoutDetails = async () => {
      try {
        setLoading(true);
        const cartService = new CartService();
        const response = await cartService.getCheckoutDetails(cartId, selectedDelivery);
        setCheckoutDetails(response);
        setPaymentLink(response.link.url);
      } catch (error) {
        console.error("Error fetching checkout details:", error);
        toast.error(error.message || "Failed to load checkout details.");
      } finally {
        setLoading(false);
      }
    };

    fetchCheckoutDetails();
  }, [cartId, selectedDelivery]);

  const handleDeliveryChange = (e) => {
    const newDeliveryType = e.target.value;
    setSelectedDelivery(newDeliveryType);
    navigate(`/cart/checkout/${cartId}/delivery/${newDeliveryType}`);
  };

  const handlePayment = () => {
    window.open(paymentLink, "_blank");
  };

  if (loading) {
    return <div className="loading">Loading checkout details...</div>;
  }

  if (!checkoutDetails) {
    return <div className="no-details">No checkout details found.</div>;
  }

  return (
    <div className="checkout-page">
      <h1>Checkout</h1>
      <div className="delivery-type-selector">
        <label>Select Delivery Option:</label><br/>
        <select value={selectedDelivery} onChange={handleDeliveryChange}>
          <option value="PEC">Road Economy (1-3 Days)</option>
          <option value="ONP">Next-Day Delivery (Overnight)</option>
        </select>
        <p className="delivery-info">
          {selectedDelivery === "PEC" 
            ? "Economy delivery typically takes 1 to 3 days."
            : "Next-day delivery will be processed for overnight delivery."}
        </p>
        <p className="delivery-note">
          Delivery will be processed after your order has been confirmed.
        </p>
      </div>

      {/* Addresses Section */}
      <div className="addresses">
        <div className="address-card">
          <h3>From Address</h3>
          <p>{checkoutDetails.addresses.seller.street}</p>
          <p>
            {checkoutDetails.addresses.seller.suburb}, {checkoutDetails.addresses.seller.city}
          </p>
          <p>
            {checkoutDetails.addresses.seller.postal_code}, {checkoutDetails.addresses.seller.country}
          </p>
        </div>
        <div className="address-card">
          <h3>To Address</h3>
          <p>{checkoutDetails.addresses.buyer.street}</p>
          <p>
            {checkoutDetails.addresses.buyer.suburb}, {checkoutDetails.addresses.buyer.city}
          </p>
          <p>
            {checkoutDetails.addresses.buyer.postal_code}, {checkoutDetails.addresses.buyer.country}
          </p>
        </div>
      </div>

      <div className="checkout-details">
        <p><strong>Price:</strong> R{checkoutDetails.price}</p>
        <p><strong>Delivery Fee:</strong> R{checkoutDetails.delivery}</p>
        <p><strong>Service Fee:</strong> R{checkoutDetails.service_fee}</p>
        <p><strong>Total:</strong> R{Number(checkoutDetails.total).toFixed(2)}</p>
        <div className="payment-button">
          <button onClick={handlePayment}>Proceed to Payment</button>
        </div>
      </div>
    </div>
  );
};
