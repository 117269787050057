import { API_BASE_URL } from "../components/Common/Constants";

class ApiService {
    constructor() {
        this.baseURL = API_BASE_URL;
        this.refreshTokenEndpoint = "/auth/token"; 
        this.accessToken = localStorage.getItem('accessToken');
        this.refreshToken = localStorage.getItem('refreshToken');
    }

    async request(endpoint, method = 'GET', body = null, options = { requiresAuth: true }) {
        const headers = new Headers();

        if (options.requiresAuth) {
            const token = localStorage.getItem('accessToken');
            if (token) {
                headers.append('Authorization', `Bearer ${token}`);
            } else {
                console.warn("No token found in localStorage.");
            }
        }

        const config = {
            method,
            headers,
        };

        if (body && !(body instanceof FormData)) {
            headers.append('Content-Type', 'application/json');
            config.body = JSON.stringify(body);
        } else if (body instanceof FormData) {
            config.body = body;  
        }

        try {
            let response = await fetch(`${this.baseURL}${endpoint}`, config);

            if (response.status === 401 && options.requiresAuth) {
                const refreshResponse = await this.refreshAccessToken();
                if (refreshResponse.ok) {
                    const newToken = localStorage.getItem('accessToken');
                    headers.set('Authorization', `Bearer ${newToken}`);
                    response = await fetch(`${this.baseURL}${endpoint}`, config);
                } else {
                    this.logout();
                    throw new Error('Session expired. Please log in again.');
                }
            }

            if (response.status === 204) {
                return null;
            }

            return await response.json();
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }

    async refreshAccessToken() {
        const body = {
            refreshToken: this.refreshToken,
        };

        const response = await fetch(`${this.baseURL}${this.refreshTokenEndpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
        }

        return response;
    }

    logout() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
    }

    createQueryParams(json) {
        return Object.entries(json)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
    }

    async get(endpoint, options) {
        return await this.request(endpoint, 'GET', null, options);
    }

    async post(endpoint, body, options) {
        return await this.request(endpoint, 'POST', body, options);
    }

    async put(endpoint, body, options) {
        return await this.request(endpoint, 'PUT', body, options);
    }
    
    async patch(endpoint, body, options) {
        return await this.request(endpoint, 'PATCH', body, options);
    }

    async delete(endpoint, options) {
        return await this.request(endpoint, 'DELETE', null, options);
    }
}

export default ApiService;
