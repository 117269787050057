import ApiService from "./ApiService";

class AddressService extends ApiService {
  // GET - FETCH ADDRESS
  getAddress = async () => {
    const endpoint = "/address/";
    return await this.get(endpoint, { requiresAuth: true });
  };

  // POST - CREATE ADDRESS
  createAddress = async (addressData) => {
    const endpoint = "/address/";
    return await this.post(endpoint, addressData, { requiresAuth: true });
  };

  // PUT - UPDATE ADDRESS
  updateAddress = async (uid, addressData) => {
    const endpoint = `/address/${uid}/`;
    return await this.put(endpoint, addressData, { requiresAuth: true });
  };
}

export default AddressService;
