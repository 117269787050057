import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ShopOwnerAddNewProduct } from "./ShopOwnerAddNewProduct";
import { Loader } from "../../Common/Loader";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import ProductService from "../../../services/ProductService";
import { ShopOwnerAddNewImage } from "./ShopOwnerAddNewImage";
import "./styles/shopowner-edit.css";

const productService = new ProductService();

export const ShopOwnerEditProduct = ({
  editableProductFields,
  handleProductInputChange,
  products,
  shop_id,
}) => {
  const [isAddNewProductVisible, setAddNewProductVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [saleToggles, setSaleToggles] = useState({});

  useEffect(() => {
    const initialToggles = {};
    products.forEach((product) => {
      initialToggles[product.uid] = product.is_on_sale;
    });
    setSaleToggles(initialToggles);
  }, [products]);

  const handleUpdateProductDetails = async (productUid) => {
    setIsLoading(true);
    try {
      const existingProduct = editableProductFields.find(
        (field) => field.product_id === productUid
      );

      const updatedProductData = {
        uid: productUid,
        name: existingProduct.product_name,
        description: existingProduct.product_description,
        price: existingProduct.price,
        is_on_sale: existingProduct.is_on_sale,
        sale_percentage: existingProduct.sale_percentage,
        images: [],
      };

      await productService.ShopOwnerUpdateProduct(
        productUid,
        updatedProductData
      );
      toast.success("Product updated successfully!");
    } catch (error) {
      console.error("Error updating product details", error);
      toast.error("Error updating product details");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProduct = (productUid) => {
    setProductToDelete(productUid);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await productService.ShopOwnerDeleteProduct(productToDelete);
      toast.success("Product deleted successfully!");
    } catch (error) {
      console.error(`Error deleting product ${productToDelete}`, error);
      toast.error("Error deleting product");
    } finally {
      setProductToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelDelete = () => {
    setProductToDelete(null);
    setShowConfirmationModal(false);
  };

  return (
    <div className="admin-product-edit">
      <h2>EDIT SHOP PRODUCTS:</h2>
      {isLoading && <Loader message="Updating Product..." />}
      <button type="button" onClick={() => setAddNewProductVisible(true)}>
        Add New Product
      </button>
      <hr />
      {isAddNewProductVisible && (
        <ShopOwnerAddNewProduct
          setAddNewProductVisible={setAddNewProductVisible}
          shop_id={shop_id}
        />
      )}

      {products.map((product) => {
        const editableProduct = editableProductFields.find(
          (field) => field.product_id === product.uid
        );
        const saleActive =
          saleToggles[product.uid] !== undefined
            ? saleToggles[product.uid]
            : editableProduct?.is_on_sale ?? product.is_on_sale;

        const priceValue = editableProduct?.price ?? product.price;
        const salePercentageFraction =
          editableProduct?.sale_percentage ?? product.sale_percentage;
        const salePercentageUser = salePercentageFraction
          ? (Number(salePercentageFraction) * 100).toFixed(0)
          : "0";
        const computedSalePrice =
          saleActive && priceValue && salePercentageFraction
            ? (
                Number(priceValue) *
                (1 - Number(salePercentageFraction))
              ).toFixed(2)
            : priceValue;

        console.log(
          `Product ${product.uid} => is_on_sale:`,
          saleActive,
          "| sale_percentage (fraction):",
          salePercentageFraction
        );

        return (
          <details key={product.uid}>
            <summary>
              <h3>{product.name}</h3>
              <div className="product-images">
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.image}
                    className="productImg"
                    alt={`Image ${index + 1} for ${product.name}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "path/to/placeholder-image.jpg";
                    }}
                  />
                ))}
              </div>
            </summary>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateProductDetails(product.uid);
              }}
            >
              <label>
                Product Name:
                <input
                  type="text"
                  name="product_name"
                  value={editableProduct ? editableProduct.product_name : ""}
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>

              <label>
                Product Description:
                <input
                  type="text"
                  name="product_description"
                  value={
                    editableProduct ? editableProduct.product_description : ""
                  }
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>

              <label>
                Price:
                <input
                  type="text"
                  name="price"
                  value={editableProduct ? editableProduct.price : ""}
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>

              <label className="toggle-label">
                <input
                  type="checkbox"
                  checked={saleActive}
                  onChange={(e) => {
                    const newSaleActive = e.target.checked;
                    console.log(
                      `Toggle clicked for product ${product.uid} new value: ${newSaleActive}`
                    );
                    setSaleToggles((prev) => ({
                      ...prev,
                      [product.uid]: newSaleActive,
                    }));
                    handleProductInputChange(
                      {
                        target: {
                          name: "is_on_sale",
                          value: newSaleActive,
                        },
                      },
                      product.uid
                    );
                    if (!newSaleActive) {
                      handleProductInputChange(
                        {
                          target: {
                            name: "sale_percentage",
                            value: "0.00",
                          },
                        },
                        product.uid
                      );
                    }
                  }}
                />
                <span className="back">
                  <span className="toggle"></span>
                </span>
              </label>

              {saleActive && (
                <>
                  <label>
                    Discount Percentage (%):
                    <input
                      type="number"
                      name="sale_percentage"
                      value={salePercentageUser}
                      onChange={(e) => {
                        let percent = Number(e.target.value);
                        if (percent > 100) percent = 100;
                        if (percent < 0) percent = 0;
                        const fraction = (percent / 100).toFixed(2);
                        handleProductInputChange(
                          {
                            target: {
                              name: "sale_percentage",
                              value: fraction,
                            },
                          },
                          product.uid
                        );
                      }}
                      min="0"
                      max="100"
                      step="1"
                    />
                  </label>

                  <label>
                    Sale Price (Read-Only):
                    <input type="text" readOnly value={computedSalePrice} />
                  </label>
                </>
              )}

              <ShopOwnerAddNewImage productUid={product.uid} />

              <button type="submit">Save Changes</button>
              <button
                type="button"
                className="delete-btn"
                onClick={() => handleDeleteProduct(product.uid)}
              >
                Delete Product
              </button>
            </form>
            <hr />
          </details>
        );
      })}

      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this product?"
          onConfirm={handleDeleteConfirmation}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
