import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useNavigate, Link } from "react-router-dom";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import AuthService from "../../services/AuthService";
import logoImg from "../../assets/logo.png";
import "./styles/login.css";
import { Loader } from "../Common/Loader";

const authService = new AuthService();

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleEmailAndPasswordLogin = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await authService.login(email, password);

      const userRole = response.user.groups[0].name;
      const userId = response.user.uid;

      toast.success("Successfully logged in");

      if (userRole === "ShopOwner") {
        const shopId = response.extras?.uid;
        navigate(`/admin/manage-shop/${shopId}`);
      } else if (userRole === "Admin") {
        navigate("/admin/shops");
      } else if (userRole === "Creator" || userRole === "Thrifter") {
        navigate(`/admin/creatives-hub-users/${userId}`);
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Login error:", error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (!email || !password) {
      console.warn("Validation failed. Email and password are required.");
      toast.error("Email and password are required");
      return false;
    }
    return true;
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="login-container">
        {loading && <Loader message="Please wait..." />}
        <img src={logoImg} alt="Logo" className="logo" />
        <h1>Login</h1>
        <p className="login-description">
          Please ensure you have applied for a shop or an account for the
          Creatives Hub and your application has been approved. If you have
          already applied and received approval, you can proceed to login.
        </p>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          aria-label="Email"
        />
        <div className="password-input">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-label="Password"
          />
          {showPassword ? (
            <RiEyeOffLine
              onClick={togglePasswordVisibility}
              aria-label="Hide Password"
              className="password-toggle-icon"
            />
          ) : (
            <RiEyeLine
              onClick={togglePasswordVisibility}
              aria-label="Show Password"
              className="password-toggle-icon"
            />
          )}
        </div>
        <button onClick={handleEmailAndPasswordLogin} disabled={loading}>
          Login
        </button>

        <div className="inline-links">
          <Link to="/forgot-password">Forgot Password</Link>
          <Link to="/apply">Don't have an Account?</Link>
        </div>
      </div>
    </>
  );
};
