import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CartService from "../../services/CartService";
import { FiPlus, FiMinus } from "react-icons/fi";
import { toast } from "react-toastify";
import { Loader } from "../Common/Loader";
import "./styles/cart-items.css";

export const CartItems = () => {
  const { cartId } = useParams();
  const cartService = new CartService();
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(true);
  // State to track updating status for each item (keyed by item uid)
  const [updatingItems, setUpdatingItems] = useState({});
  const navigate = useNavigate();

  // Modified fetchCart that accepts a flag indicating if this is the initial load.
  const fetchCart = async (isInitial = true) => {
    try {
      if (isInitial) setLoading(true);
      const response = await cartService.viewCart(cartId);
      setCart(response);
    } catch (error) {
      console.error("Error fetching cart items:", error);
      toast.error(
        error.response?.data?.message || "Failed to load cart items."
      );
    } finally {
      if (isInitial) setLoading(false);
    }
  };

  useEffect(() => {
    fetchCart(true);
  }, [cartId]);

  // Remove product from the cart (unchanged)
  const handleDeleteProduct = async (productUid) => {
    try {
      await cartService.removeItemFromCart(cartId, productUid);
      setCart({
        ...cart,
        items: cart.items.filter((item) => item.product.uid !== productUid),
      });
      toast.success("Product removed from cart successfully!");
    } catch (error) {
      if (
        error.message &&
        error.message.includes("Unexpected end of JSON input")
      ) {
        setCart({
          ...cart,
          items: cart.items.filter((item) => item.product.uid !== productUid),
        });
        toast.success("Product removed from cart successfully!");
      } else {
        console.error("Error removing product from cart:", error);
        toast.error(
          error.message || "Failed to remove product from cart."
        );
      }
    }
  };

  // Decrement item quantity
  const handleDecrement = async (itemUid) => {
    // Mark this item as updating
    setUpdatingItems((prev) => ({ ...prev, [itemUid]: true }));
    try {
      const response = await cartService.decrementCartItemQuantity(cartId, itemUid);
      if (
        response &&
        response.message &&
        response.message.toLowerCase().includes("removed")
      ) {
        toast.success("Item Removed");
      } else {
        toast.success("Item quantity decreased!");
      }
      // Refresh the cart without triggering the global loader
      await fetchCart(false);
    } catch (error) {
      console.error("Error decrementing quantity:", error);
      toast.error(
        error.message || "Failed to decrement item quantity."
      );
    } finally {
      // Remove updating flag for the item
      setUpdatingItems((prev) => {
        const newState = { ...prev };
        delete newState[itemUid];
        return newState;
      });
    }
  };

  // Increment item quantity
  const handleIncrement = async (itemUid) => {
    setUpdatingItems((prev) => ({ ...prev, [itemUid]: true }));
    try {
      await cartService.incrementCartItemQuantity(cartId, itemUid);
      toast.success("Item quantity increased!");
      await fetchCart(false);
    } catch (error) {
      console.error("Error incrementing quantity:", error);
      toast.error(
        error.message || "Failed to increment item quantity."
      );
    } finally {
      setUpdatingItems((prev) => {
        const newState = { ...prev };
        delete newState[itemUid];
        return newState;
      });
    }
  };

  if (loading) {
    return <Loader message="Loading cart items..." />;
  }

  if (!cart || !cart.items || cart.items.length === 0) {
    return <div className="no-items">No items found in this cart.</div>;
  }

  const handleCheckout = () => {
    navigate(`/cart/checkout/${cartId}/delivery/PEC/`);
  };

  return (
    <div className="cart-items-section">
      <h2>Cart Items</h2>
      <div className="cart-details">
        <p>
          <strong>Created Date:</strong>{" "}
          {new Date(cart.date_created).toLocaleDateString()}
        </p>
        <p>
          <strong>Last Modified:</strong>{" "}
          {new Date(cart.last_modified).toLocaleDateString()}
        </p>
      </div>
      <div className="cart-items">
        {cart.items.map((item) => (
          <div className="cart-item" key={item.uid}>
            <div className="item-image">
              <img
                src={item.product.images[0]?.image}
                alt={item.product.name}
              />
            </div>
            <div className="item-details">
              <h3>{item.product.name}</h3>
              <p>{item.product.description}</p>
              <p>
                <strong>Price:</strong> R{item.product.price}
              </p>
              <p>
                <strong>Quantity:</strong>{" "}
                {updatingItems[item.uid] ? "Updating..." : item.qty}
              </p>
              <p>
                <strong>Total:</strong> R
                {(item.qty * parseFloat(item.product.price)).toFixed(2)}
              </p>
              <div className="cart-item-buttons">
                <button
                  className="button"
                  onClick={() => handleDecrement(item.uid)}
                >
                  <FiMinus />
                </button>
                <button
                  className="button"
                  onClick={() => handleIncrement(item.uid)}
                >
                  <FiPlus />
                </button>
                <button
                  className="button delete-btn"
                  onClick={() => handleDeleteProduct(item.product.uid)}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button className="checkout-btn" onClick={handleCheckout}>
        Checkout Cart
      </button>
    </div>
  );
};
