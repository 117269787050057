import React, { useEffect, useMemo, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RiShoppingCart2Fill, RiTruckLine, RiHome3Line } from "react-icons/ri";
import { ProductCard } from "./ProductCard";
import { ShopReviews } from "../Review/ShopReviews";
import { useCart } from "../Cart/CartContext";
import { Helmet } from "react-helmet-async";
import LoginRequiredModal from "../Common/LoginRequiredModal";
import AuthService from "../../services/AuthService";
import ShopService from "../../services/ShopService";
import ProductService from "../../services/ProductService";
import ReviewService from "../../services/ReviewService";
import CartService from "../../services/CartService";
import "./styles/productlist.css";
import { Loader } from "../Common/Loader";

const shopService = new ShopService();
const productService = new ProductService();
const reviewService = new ReviewService();
const authService = new AuthService();
const cartService = new CartService();

export const ProductList = React.memo(() => {
  const { shop_id } = useParams();
  const queryClient = useQueryClient();
  const { addToCart } = useCart();
  const [reviews, setReviews] = useState([]);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [cartsCount, setCartsCount] = useState(0);

  const fetchShop = useMemo(
    () => () => shopService.getShop(shop_id),
    [shop_id]
  );
  const fetchProducts = useMemo(
    () => () => productService.getProducts(shop_id),
    [shop_id]
  );
  const fetchReviews = useMemo(
    () => () => reviewService.getReviews(shop_id),
    [shop_id]
  );

  const {
    data: shopData,
    error: shopError,
    isLoading: shopLoading,
  } = useQuery({
    queryKey: ["shop", shop_id],
    queryFn: fetchShop,
  });

  const {
    data: productsData,
    error: productsError,
    isLoading: productsLoading,
  } = useQuery({
    queryKey: ["products", shop_id],
    queryFn: fetchProducts,
  });

  const {
    data: reviewsData,
    error: reviewsError,
    isLoading: reviewsLoading,
  } = useQuery({
    queryKey: ["reviews", shop_id],
    queryFn: fetchReviews,
  });

  // Fetch the number of carts for the authenticated user
  useEffect(() => {
    const fetchCartsCount = async () => {
      try {
        const response = await cartService.listCarts();
        setCartsCount(response.results.length);
      } catch (error) {
        console.error("Error fetching carts count:", error);
      }
    };

    // Only fetch carts if the user is authenticated
    if (authService.isAuthenticated()) {
      fetchCartsCount();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const products = productsData?.results || [];

  const onAddReview = (newReview) => {
    setReviews((prevReviews) => [...prevReviews, newReview]);
  };

  const handleCartClick = () => {
    if (!authService.isAuthenticated()) {
      setLoginModalOpen(true);
    }
  };

  return (
    <div>
      {shopLoading ? (
        <Loader message="Loading shop details..." />
      ) : shopError ? (
        <p>Error fetching shop data</p>
      ) : shopData ? (
        <div
          className="product-list-section"
          style={{
            backgroundImage: `url(${shopData.background})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <Helmet>
            <title>Creatives SA - {shopData.name}</title>
          </Helmet>
          <nav className="shop-navbar">
            <a href="#home" className="logo">
              {shopData.name}
            </a>
            <ul className="shop-menu">
              <li>
                <a href="#shop-footer">Contact</a>
              </li>
              <li>
                {authService.isAuthenticated() ? (
                  <Link to="/carts" className="cart-icon">
                    <i>
                      <RiShoppingCart2Fill />
                    </i>
                    <span className="badge">{cartsCount}</span>
                  </Link>
                ) : (
                  <div className="cart-icon" onClick={handleCartClick}>
                    <i>
                      <RiShoppingCart2Fill />
                    </i>
                    <span className="badge">{cartsCount}</span>
                  </div>
                )}
              </li>
            </ul>
          </nav>
          <div className="shop-header" id="home">
            <div className="shop-header-content">
              <h1>{shopData.name}</h1>
              <p>{shopData.description}</p>
            </div>
            <div className="shop-header-image">
              <img src={shopData.image} alt={` ${shopData.name}`} />
            </div>
          </div>
          <h2 className="products-title">Products from {shopData.name}</h2>
          <div className="product-list">
            {productsLoading ? (
              <Loader message="Loading products..." />
            ) : productsError ? (
              <p>Error fetching products</p>
            ) : products.length > 0 ? (
              products.map((product) => (
                <ProductCard
                  key={product.uid}
                  product={product}
                  addToCart={addToCart}
                />
              ))
            ) : (
              <p>No products available</p>
            )}
          </div>
          <div className="reviews" id="reviews">
            <ShopReviews
              reviews={reviewsData?.results || []}
              shopId={shop_id}
              onAddReview={onAddReview}
            />
          </div>
          <div className="content1"></div>
          <footer className="shop-footer" id="shop-footer">
            <div className="main-content">
              <div className="left box">
                <h2>About us</h2>
                <div className="content">
                  <p>{shopData.about}</p>
                </div>
              </div>
              <div className="center box">
                <h2>CONTACT DETAILS</h2>
                <div className="content">
                  <div className="place">
                    <span className="fas fa-map-marker-alt"></span>
                    <span className="text">
                      {shopData.address
                        ? `${shopData.address.street}, ${shopData.address.suburb}, ${shopData.address.city}, ${shopData.address.postal_code}, ${shopData.address.country}`
                        : "Address not available"}
                    </span>
                  </div>
                  <div className="phone">
                    <span className="fas fa-phone-alt"></span>
                    <span className="text">{shopData.owner_phone}</span>
                  </div>
                  <div className="email">
                    <span className="fas fa-envelope"></span>
                    <span className="text">{shopData.owner_email}</span>
                  </div>
                </div>
              </div>
              <div className="right box">
                <h2>LINKS</h2>
                <div className="content">
                  <form action="#">
                    <div className="LinkButton1">
                      <p>
                        {authService.isAuthenticated() ? (
                          <Link to="/carts" className="LinkButton">
                            <i>
                              <RiShoppingCart2Fill />
                            </i>{" "}
                            Cart
                          </Link>
                        ) : (
                          <span
                            className="LinkButton"
                            onClick={handleCartClick}
                            style={{ cursor: "pointer" }}
                          >
                            <i>
                              <RiShoppingCart2Fill />
                            </i>{" "}
                            Cart
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="LinkButton2">
                      <p>
                        <Link to="/track-orders" className="LinkButton">
                          <i>
                            <RiTruckLine />
                          </i>{" "}
                          Track Your Order(s)
                        </Link>
                      </p>
                    </div>
                    <div className="LinkButton3">
                      <p>
                        <Link to="/" className="LinkButton">
                          <i>
                            <RiHome3Line />
                          </i>{" "}
                          Home
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="bottom-text">
              <center>Thank You For Shopping With {shopData.name}</center>
            </div>
          </footer>
          {loginModalOpen && (
            <LoginRequiredModal
              isOpen={loginModalOpen}
              closeModal={() => setLoginModalOpen(false)}
            />
          )}
        </div>
      ) : (
        <p>Shop data is not available</p>
      )}
    </div>
  );
});

export default ProductList;
