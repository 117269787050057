import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./styles/payment-success.css"

export const PaymentSuccess = () => {
  const { paymentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState(null);

  useEffect(() => {
    // Extract query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const transactionId = queryParams.get("TransactionId");
    const amount = queryParams.get("Amount");
    const status = queryParams.get("Status");
    const statusMessage = queryParams.get("StatusMessage");
    const currencyCode = queryParams.get("CurrencyCode");

    // Set the payment details (for example, in this case, you might not need all of these)
    setPaymentDetails({
      transactionId,
      amount,
      status,
      statusMessage,
      currencyCode,
    });
  }, [location]);

  return (
    <div className="payment-success">
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase!</p>

      {paymentDetails ? (
        <>
          <p>Your transaction has been successfully processed, and your order is now confirmed.</p>
          <p><strong>Order Confirmation:</strong> You’ll receive an email shortly with all the details of your order.</p>
          <p><strong>Tracking Your Order:</strong> Once your order is shipped, you’ll receive another email with tracking information.</p>
          <p><strong>Customer Support:</strong> If you have any questions or concerns, our support team is here to assist you.</p>
          <p>What to do now?</p>
          <ul>
            <li>Continue Shopping: Feel free to explore more amazing shops and products on Creatives SA!</li>
            <li>Visit Your Order History: View all your orders and manage them in your profile.</li>
          </ul>

          <p>We appreciate your support and look forward to delivering your order soon!</p>
        </>
      ) : (
        <p>Loading payment details...</p>
      )}

      {/* Go to Home Button */}
      <div>
        <button onClick={() => navigate("/")}>Go to Home</button>
      </div>
    </div>
  );
};