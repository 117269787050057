import React, { createContext, useContext, useState } from "react";
import CartService from "../../services/CartService";
import { toast } from "react-toastify";

export const CartContext = createContext();

const cartService = new CartService();

export const CartProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const addToCart = async (product) => {
    const shopUid = product.shop.uid;
    const productUid = product.uid;
    const qty = 1;

    try {
      setLoading(true);
      const cartsResponse = await cartService.listCarts();
      const existingCart = cartsResponse.results.find(
        (cart) => cart.shop === shopUid
      );

      let cartId;

      if (existingCart) {
        cartId = existingCart.uid;
        // Fetch detailed cart info to check for existing items
        const cartDetails = await cartService.viewCart(cartId);
        const existingItem = cartDetails.items.find(
          (item) => item.product.uid === productUid
        );
        if (existingItem) {
          // Product already exists, so just increment its quantity
          await cartService.incrementCartItemQuantity(cartId, existingItem.uid);
          toast.success(`${product.name} quantity increased in the cart!`);
          return;
        }
      } else {
        // No cart exists for this shop, so create one
        const newCart = await cartService.createCart(shopUid);
        cartId = newCart.uid;
      }
      // If product is not in the cart, add it
      await cartService.addItemToCart(cartId, productUid, qty);
      toast.success(`${product.name} added to the cart!`);
    } catch (error) {
      console.error("Error in addToCart:", error);
      toast.error("Failed to add item to cart.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CartContext.Provider value={{ addToCart, loading }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
