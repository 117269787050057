import React, { useState } from "react";
import Slider from "react-slick";
import { StarRating } from "./StarRating";
import { ReviewForm } from "./ReviewForm";
import LoginRequiredModal from "../Common/LoginRequiredModal";
import AuthService from "../../services/AuthService";
import "./styles/shop-reviews.css";

export const ShopReviews = ({ reviews = [], shopId, onAddReview }) => {
  const [isReviewFormVisible, setReviewFormVisible] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  
  const authService = new AuthService();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    dotsClass: "slick-dots custom-dots",
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const handleToggleReviewForm = () => {
    if (!authService.isAuthenticated()) {
      setLoginModalOpen(true);
    } else {
      setReviewFormVisible(!isReviewFormVisible);
    }
  };

  return (
    <div className="shop-reviews-section">
      <Slider {...settings} className="reviews-slider">
        {reviews.map((review) => (
          <div key={review.uid} className="review-card">
            <p className="user-info">{review.author_fullname}</p>
            <div className="user-info">
              <StarRating rating={review.rating} />
            </div>
            <p className="review-text">{review.content}</p>
            <hr className="divider" />
          </div>
        ))}
      </Slider>

      <button
        type="button"
        onClick={handleToggleReviewForm}
        className="add-review-button"
      >
        {isReviewFormVisible ? "Cancel Review" : "Add Review"}
      </button>

      {isReviewFormVisible && (
        <ReviewForm shopId={shopId} onAddReview={onAddReview} />
      )}

      <LoginRequiredModal 
        isOpen={loginModalOpen} 
        closeModal={() => setLoginModalOpen(false)}
      />
    </div>
  );
};
