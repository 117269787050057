import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './styles/admin-edit-shop-subscription.modal.css';

export const AdminEditShopSubscriptionModal = ({ 
  initialSubscription, 
  onSave, 
  onClose, 
  onCancel,
  subscriptionPackages  // Expect an array of packages with either a uid or subscription_package_id, and package_name
}) => {
  // Use initialSubscription (a UUID string) or empty string.
  const [subscription, setSubscription] = useState(initialSubscription || "");
  const [isValid, setIsValid] = useState(!!initialSubscription);

  const handleSave = () => {
    if (!subscription) {
      toast.warning('Please select a subscription package or cancel');
      return;
    }
    // IMPORTANT: Ensure that the subscription value here is the valid identifier (usually a UUID) expected by the backend.
    onSave(subscription);
    onClose();
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value; // remains a string
    setSubscription(selectedValue);
    setIsValid(selectedValue !== "");
  };

  console.log('subscriptionPackages:', subscriptionPackages);

  return (
    <div className="admin-edit-shop-subscription-modal">
      <div className="modal-content">
        <h2>Edit Shop Subscription</h2>
        <label>
          Subscription:
          <select value={subscription} onChange={handleSelectChange}>
            <option value="">None</option>
            {subscriptionPackages && subscriptionPackages.map((pkg, idx) => {
              // Use pkg.uid if available, else pkg.subscription_package_id, 
              // otherwise fall back to a generated key.
              const keyVal = pkg.uid || pkg.subscription_package_id || `pkg-${idx}`;
              const valueVal = pkg.uid || pkg.subscription_package_id || `pkg-${idx}`;
              return (
                <option key={keyVal} value={valueVal}>
                  {pkg.package_name}
                </option>
              );
            })}
          </select>
        </label>
        {!isValid && (
          <p className="error-message">Please select a subscription package or cancel</p>
        )}
        <div className="modal-actions">
          <button className="save-btn" onClick={handleSave} disabled={!isValid}>
            Save Changes
          </button>
          <button className="cancel-btn" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminEditShopSubscriptionModal;
